<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Master from "@/apis/Master";
import Vue from "vue";
/**
 * Product Detail Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "COLLECTION PRODUCT DETAILS",
      productData:[],
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "COLLECTION PRODUCT LIST",
          href: "/collection-products"
        },
        {
          text: "COLLECTION PRODUCT DETAILS",
          active: true
        }
      ],
      checkbox:[],
      isCheckAll:false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "productId",
      sortDesc: false,
      selected:"true",
      fields: [
        { key: "checkbox", sortable: false, label: "" },
        { key: "pimage", sortable: false, label: "PRODUCT IMAGE",thStyle: { color: "black", "font-size":"16px"}  },
        { key: "title", sortable: true, label: "PRODUCT NAME",thStyle: { color: "black", "font-size":"16px"}  },
        { key: "variants[0].sku", sortable: true, label: "PRODUCT SKU",thStyle: { color: "black", "font-size":"16px"}  },
        { key: "variants[0].stock", sortable: true, label: "STOCK",thStyle: { color: "black", "font-size":"16px"}  },

      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.productData.length;
    }
  },
  mounted() {
    this.totalRows = this.productData.length;

    Master.collectionProductDetails({
      collectionId: localStorage.getItem('collectionId')
    }).then((res) => {
      console.log(res)
      this.productData = res.data.data;
    })
  },
  methods: {
    /**
     * Selected image shows
     */
    checkAll: function(){
      this.isCheckAll = !this.isCheckAll;
      this.checkbox = [];
      if(this.isCheckAll){ // Check all
        for (var key in this.productData) {
          this.checkbox.push(this.productData[key].productId);
        }
      }
    },

    async bulkDelete(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        if (confirm('Are you sure you want to delete this item?')) {
          Master.collectionProductBulkDelete({
            productIds: this.checkbox,
            collectionId: localStorage.getItem('collectionId')
          }).then((res) => {
            // this.successMessage = res.data.message;
            // this.$bvToast.toast(""+res.data.message+"", {
            //   title: `Success Message`,
            //   variant: 'success',
            //   solid: true
            // });

            Vue.swal({
              position: "center",
              icon: "success",
              title: ""+res.data.message+"",
              showConfirmButton: false,
              timer: 1500
            });
            this.checkbox = []
            this.isCheckAll = false
            Master.collectionProductDetails({
              collectionId: localStorage.getItem('collectionId')
            }).then((res) => {
              console.log(res)
              this.productData = res.data.data;
            })
          })
        }
      }
    },


    productIdremoved(){
      localStorage.removeItem('collectionId')
    },
    deleteProduct(){

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">

          <div class="card-body">

            <b-tabs nav-class="nav-tabs-custom">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <div class="btn-toolbar">
                      <a href="javascript:void(0);" class="btn btn-danger mb-2 mr-1" @click="bulkDelete">
                        <i class="mdi mdi-trash-can mr-2"></i> Delete
                      </a>
                    </div>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
            <div class="row">

              <div class="col-xl-12">
                <div class="table-responsive">
                  <b-table
                      class="table-centered"
                      :items="productData"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      show-empty
                      sort-icon-left
                  >
                    <template  v-slot:cell(pimage) = "data">
                      <img
                          v-if="data.item.variants[0] != null"
                          :src="data.item.variants[0].images[0].imageUrl"
                          alt="product-img"
                          title="product-img"
                          class="avatar-md"
                      />
                    </template>
                    <template v-slot:cell(title)="row">
                      <p class="badge font-size-12">
                        {{row.item.title}}
                      </p>
<!--                      <ul class="pl-1" style="list-style-type: none;">-->
<!--                        <li>-->
<!--                          <p class="badge font-size-6" style="white-space: pre-wrap;">-->
<!--                            {{row.item.brand}}-->
<!--                          </p>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                          <p class="badge font-size-12 text-breaker">-->
<!--                            {{row.item.title}}-->
<!--                          </p>-->
<!--                        </li>-->
<!--                      </ul>-->
                    </template>
                    <template v-slot:cell(variants[0].sku)="row">
                      <p class="badge font-size-12" v-if="row.item.variants[0] != null">
                        {{row.item.variants[0].sku}}
                      </p>
                    </template>
                    <template v-slot:head(checkbox)>
                      <div class="form-check mb-1">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            v-model="isCheckAll"
                            :id="`customercheck`"
                            @click='checkAll()'
                        />
                        <label class="form-check-label" :for="`customercheck`"></label>
                      </div>
                    </template>
                    <template v-slot:cell(variants[0].stock)="row">

                      <p class="badge font-size-12" v-if="row.item.variants[0] != null">
                        <span v-if="row.item.variants[0].stock > 0">{{row.item.variants[0].stock}}</span>
                        <span v-else class="badge-soft-danger badge font-size-9">STOCK OUT</span>
                      </p>

                    </template>
                    <template v-slot:cell(checkbox)="row">

                      <div class="form-check mb-3">
                        <input
                            type="checkbox"
                            class="form-check-input"
                            v-model="checkbox"
                            :id="`customercheck${row.index}`"
                            :value="row.item.productId"
                        />
                        <label class="form-check-label" :for="`customercheck${row.index}`">&nbsp;</label>
                      </div>

                    </template>


                  </b-table>

                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>


              </div>

            </div>
            <!-- end row -->

            </b-tabs>


          </div>

        </div>
        <div class="text-right">
          <router-link to="/collection-products"><b-button class="ml-1" variant="danger"  @click="productIdremoved()">BACK</b-button></router-link>
        </div>
        <br/>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->


    <!-- end row -->
  </Layout>
</template>